import * as React from "react"
import "./numbers.css"

const NumbersBar = () => (
  <div className="numbersBar">
    <div className="numberCard">
      <h3>5.000</h3>
      <span>Plannings téléchargés</span>
    </div>

    <div className="numberCard">
      <h3>30</h3>
      <span>Villes en Europe</span>
    </div>

    <div className="numberCard">
      <h3>2.000</h3>
      <span>Bons plans</span>
    </div>

    <div className="numberCard lastNumber">
      <h3>30</h3>
      <span>TripHelpR</span>
    </div>
  </div>
)

export default NumbersBar
