import * as React from "react"
import { graphql, navigate } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"

import Layout from "../components/layout"
import NumbersBar from '../components/NumbersBar'
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = ({ data }) => {

  const [formValue, setFormValue] = React.useState({});

  async function _onSubmit(e) {
    e.preventDefault();

    const result = await addToMailchimp(formValue.email, {
      city: formValue.destination,
      variant: formValue.type,
    });

    console.log(result)

    fetch('https://api.tripplanr.co/api/summer-free', {
      body: JSON.stringify({
        variant: formValue.type,
        product_name: formValue.destination,
        email: formValue.email,
      }),
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      }
    });

    navigate(`/planning-unlock/${formValue.destination}/${formValue.type}/#success`.replace(' ', '-').toLowerCase());

    return false;
  }

  function onChange(name) {
    return (e) => {
      setFormValue({...formValue, [name]: e.target.value});
//      navigate(`/planning/${e.target.value.toLowerCase()}`)
    }
  }

  return (
    <Layout>
      <Seo title="TripPlanR: Vos plannings de vacances sur mesure" />

      <div className="hero">
        <div className="heroLeft">
          <h1>
            <span className="textOrange">Accédez aux bons plans</span>
            <br />
            des habitants amoureux de leur ville&nbsp;!
          </h1>
        </div>
        <div className="heroForm">
          <h2>
            Recevez votre planning gratuitement&nbsp;!
          </h2>
          <form onSubmit={_onSubmit} className="subscribeForm">
          <select
              name="destination"
              onChange={onChange('destination')}
            >              
              <option disabled hidden selected>
                Choisissez votre destination
              </option>
              {data.allCitiesJson.nodes.map(node => (
                <option>{node.name}</option>
              ))}
            </select>
            <select
              name="type"
              onChange={onChange('type')}
            >
              <option disabled hidden selected>
                Type de planning
              </option>

              <option value="visite">Visite</option>
              <option value="pour-sortir">Pour sortir</option>
              <option value="détente">Détente</option>
            </select>
            <input
                name="email"
                placeholder="Votre adresse email"
                onChange={onChange('email')}
                // value={emailForm}
                required
              />
            <button className="button">Découvrir !</button>
          </form>
        </div>
      </div>

      <NumbersBar />

      <div className="homeSection accent">
        <h2>
          Qui connaît mieux les bons plans d'une ville que ses habitants ?
        </h2>
        <p>
          TripPlanR est la plateforme regroupant les meilleurs bons plans des
          habitants dans votre poche !
          <br />
          Nous relions en 1 clic les activités, restaurants et bars sur votre
          smartphone.
          <br />
          Ne perdez plus votre temps, ni votre argent à rechercher le restaurant
          parfait ou l'activité de vos rêves.
          <br />
        </p>
      </div>
      <div className="homeSection">
        <h2>Quelques exemples de plannings</h2>

        <div className="planningRow">
          <div className="phoneColumn">
            <StaticImage
              src="../images/phone.png"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
            />
          </div>
          <div className="planningColumn">
            <div className="planningLine">
                <StaticImage
                  src="../images/cities/photo2.png"
                  height={150}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                />
              <div className="infoColumn">
                <h3>
                  Sorties à <span className="cityName">Barcelone</span>
                </h3>
                <ul className="infoList">
                  <li>5 jours</li>
                  <li>7 activités</li>
                  <li>10 restaurants</li>
                  <li>5 bars</li>
                </ul>
              </div>
            </div>

            <div className="planningLine">
                <StaticImage
                  src="../images/cities/photo1.png"
                  height={150}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                />
              <div className="infoColumn">
                <h3>
                  Visites à <span className="cityName">Paris</span>
                </h3>
                <ul className="infoList">
                  <li>5 jours</li>
                  <li>17 activités</li>
                  <li>10 restaurants</li>
                  <li>2 bars</li>
                </ul>
              </div>
            </div>

            <div className="planningLine">
                <StaticImage
                  src="../images/cities/photo3.png"
                  height={150}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                />
              <div className="infoColumn">
                <h3>
                  Détente à <span className="cityName">Porto</span>
                </h3>
                <ul className="infoList">
                  <li>5 jours</li>
                  <li>8 activités</li>
                  <li>10 restaurants</li>
                  <li>1 bar</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="homeSection accent">
        <h2>Envie d'un planning sur mesure ?</h2>
        <p>Fixons un rendez-vous pour créer votre planning de rêve !</p>
        <a
          className="button"
          href="https://calendly.com/tripplanr-b2c/45m-call-b2c"
          target="_blank"
          rel="noreferrer"
          title="Découvrez notre solution"
        >
          Parlons-en
        </a>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allCitiesJson(sort: { fields: name }) {
      nodes {
        internal {
          type
        }
        name
      }
    }
  }
`

export default IndexPage
